'use client';

import React from 'react';
import gsap from 'gsap';
import { SmartLink } from 'src/tapestry/core/links/smart-link';
import { translate } from 'src/utils/localization';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import './EducationalLinks.scss';

const DESKTOP_MENU_BREAKPOINT = 1280;

export const EducationalLinks = ({
  categoryLinks,
  learnLinks,
  className = '',
  menuLayout = false,
  onHoverCallback,
}) => {
  const { locale } = useTranslationContext();

  const onCategoryEnter = e => {
    if (!menuLayout || (menuLayout && window.innerWidth < DESKTOP_MENU_BREAKPOINT)) return;
    const head = e.currentTarget.getElementsByTagName('p')[0];
    const subhead = e.currentTarget.getElementsByTagName('p')[1];
    gsap.to(head, { duration: 0.4, y: -5, ease: 'power2.inOut' });
    gsap.fromTo(subhead, { y: 5 }, { duration: 0.4, opacity: 1, y: -5, ease: 'power2.inOut' });
    if (onHoverCallback) {
      const { category } = e.currentTarget.dataset;
      onHoverCallback(category);
    }
  };

  const onCategoryLeave = e => {
    if (!menuLayout || (menuLayout && window.innerWidth < DESKTOP_MENU_BREAKPOINT)) return;
    const head = e.currentTarget.getElementsByTagName('p')[0];
    const subhead = e.currentTarget.getElementsByTagName('p')[1];
    gsap.to(head, { duration: 0.4, y: 0, ease: 'power2.inOut' });
    gsap.to(subhead, { duration: 0.4, y: -5, opacity: 0, ease: 'power2.inOut' });
  };

  return (
    <div className={className}>
      <p className="text-xxs leading-none text-greyscale-dark font-medium tracking-doublewide uppercase MenuDesktopAnimation__Step MenuMobileAnimation__Step FooterAnimation__Right">
        {translate('browse-by-category', locale)}
      </p>
      <ul className="list-none">
        {categoryLinks.map(link => {
          return (
            <li
              key={link.label}
              onMouseEnter={onCategoryEnter}
              onMouseLeave={onCategoryLeave}
              className={`mb-sm ${
                menuLayout ? '' : 'lg:mb-md'
              } MenuDesktopAnimation__Step MenuMobileAnimation__Step FooterAnimation__Right`}
              data-category={link.url.replace('/magazine/', '')}
            >
              <SmartLink
                className="no-underline text-2xl sm:text-5xl text-greyscale-black font-sansbold font-bold leading-none"
                href={`${link.url}`}
              >
                <p>{link.label}</p>
              </SmartLink>
              <p
                className={`hidden ${menuLayout &&
                  'lg:block'} opacity-0 leading-none text-greyscale-dark mt-sm`}
              >
                {translate(`subhead-${link.url.replace('/magazine/', '')}`, locale)}
              </p>
            </li>
          );
        })}
      </ul>
      <div className="mt-lg sm:mt-xl lg:mt-xxl">
        <p className="text-xxs text-greyscale-dark font-medium tracking-doublewide uppercase MenuDesktopAnimation__Step MenuMobileAnimation__Step FooterAnimation__Right">
          {translate('more-from-ws', locale)}
        </p>
        <ul className="list-none MenuDesktopAnimation__Step MenuMobileAnimation__Step EducationalLinks">
          {learnLinks.map(link => {
            return (
              <li key={link.label} className="block sm:inline-block FooterAnimation__Right">
                <SmartLink
                  href={link.url}
                  className="no-underline text-greyscale-black font-semibold"
                >
                  {link.label}
                </SmartLink>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
