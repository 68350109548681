'use client';

import React, { useLayoutEffect, useRef } from 'react';
import NextImage from 'next/image';
import gsap from 'gsap';
import { SmartLink } from 'src/tapestry/core/links/smart-link';
import { translate } from 'src/utils/localization';
import { isWindowDefined } from 'src/utils/window';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { EducationalLinks } from '../EducationalLinks';
import { useMagazineNavContext } from '../MagazineNavContext';
import { SocialMedia } from '../SocialMedia';
import { ReadAboutUs } from '../ReadAboutUs';
import { useSuppressBrowserChromeResize } from '../SuppressChromeResizeHook';
import { UnderlineButton } from '../UnderlineButton';
import DesktopCategoryAnimation from './desktop-category-animation';
import MobileCategoryAnimation from './mobile-category-animation';
import DesktopArticleDarkAnimation from './desktop-article-dark-animation';
import MenuOpenAnimation from './menu-open-animation';
import MenuCloseAnimation from './menu-close-animation';
import IconWealthsimple from './icon-wealthsimple';
import IconMagazine from './icon-magazine';
import { navContent } from './MagazineNav.translations';
import './MagazineNav.scss';

const CATEGORIES = ['news', 'money-diaries', 'finance-for-humans', 'money-and-the-world'].reverse();
const DESKTOP_BREAKPOINT = 960;

export const MagazineNav = ({ menuImagesByCategory }) => {
  const { isNavOpen, setIsNavOpen, pageType, isDarkMode } = useMagazineNavContext();
  const { locale } = useTranslationContext();
  const content = navContent[locale];
  const aboutContent = content.about;
  const categoryImages = [useRef(), useRef(), useRef(), useRef()];
  const navRef = useRef(null);
  const hamburgerTopRef = useRef(null);
  const hamburgerBottomRef = useRef(null);
  const navigationTimelineRef = useRef(null);
  const isOpenRef = useRef(false);
  const firstRenderRef = useRef(true);
  const isMenuIsAnimating = useRef(false);

  useLayoutEffect(() => {
    // Remove the navbar from view if the user agent is the mobile app's webview
    if (navigator.userAgent.indexOf('WealthsimpleInvest') !== -1) {
      document.querySelector('header').style.display = 'none';
    } else {
      // header intro animation
      const delay = 0.5;
      const ease = 'power2.inOut';
      gsap.to(hamburgerTopRef.current, { duration: 0.25, y: 4, delay, ease });
      gsap.to(hamburgerBottomRef.current, { duration: 0.25, y: -4, delay, ease });
      gsap.set(navRef.current, { autoAlpha: 0, y: 0, display: 'block' });
      gsap.fromTo(
        '.MagHeader',
        { autoAlpha: 0, y: -5 },
        { duration: 1, delay: 0.75, autoAlpha: 1, y: 0 }
      );
    }
  }, []);

  const resize = innerWidth => {
    // kill all active scrollTrigger instances
    if (navigationTimelineRef.current) {
      // this is ugly, but it needs to be done with current structure
      if (navigationTimelineRef.current.menuSwapTl) {
        navigationTimelineRef.current.menuSwapTl.scrollTrigger.kill();
        navigationTimelineRef.current.menuSwapTl.kill();
        navigationTimelineRef.current.shadowStartTl.scrollTrigger.kill();
        navigationTimelineRef.current.shadowStartTl.kill();
      } else {
        navigationTimelineRef.current.scrollTrigger.kill();
        navigationTimelineRef.current.kill();
      }
    }
    // assign scroll animation for device resolutions and layouts
    if (innerWidth <= DESKTOP_BREAKPOINT) {
      navigationTimelineRef.current = MobileCategoryAnimation(
        pageType,
        isDarkMode,
        isOpenRef.current
      );
    } else if (isDarkMode) {
      navigationTimelineRef.current = DesktopArticleDarkAnimation(isOpenRef.current);
    } else {
      navigationTimelineRef.current = DesktopCategoryAnimation(pageType === 'article');
    }
  };

  useSuppressBrowserChromeResize(innerWidth => {
    resize(innerWidth);
  });

  const toggleMenu = () => {
    if (isMenuIsAnimating.current) return;
    // need a mutable version for resize function
    isOpenRef.current = !isNavOpen;
    setIsNavOpen(!isNavOpen);
  };

  const onCategoryHover = category => {
    const selected = document.querySelectorAll(`[data-category="${category}"]`)[0];

    // find selected index
    let selectedIndex;
    categoryImages.forEach((ref, index) => {
      if (ref.current.dataset.category === selected.dataset.category) selectedIndex = index;
    });

    // ignore if currently selected
    if (categoryImages[0].current.dataset.category === selected.dataset.category) {
      return;
    }

    // manage order and swap z-indices
    const selectedRef = categoryImages[selectedIndex];
    categoryImages.splice(selectedIndex, 1);
    categoryImages.unshift(selectedRef);
    const zIndices = [24, 23, 22, 21];
    categoryImages.forEach((ref, index) => {
      ref.current.style['z-index'] = zIndices[index];
    });

    gsap.fromTo(
      selectedRef.current,
      {
        autoAlpha: 0,
        left: Math.random() * 100 - 50,
        top: Math.random() * 100 - 50,
        scale: 0.95,
        y: 5,
      },
      {
        scale: 1,
        y: 0,
        duration: 0.5,
        autoAlpha: 1,
        ease: 'power2.out',
      }
    );
  };

  const getCategoryImageStyles = index => {
    let x = 0;
    let y = 0;
    let zIndex;

    // generate positions for onOpen, and retain last positions for onClose render
    if (isWindowDefined()) {
      if (isNavOpen) {
        x = Math.random() * 100 - 50;
        y = Math.random() * 100 - 50;
      } else {
        const el = document.getElementsByClassName('Menu__CategoryImages')[index];
        x = gsap.getProperty(el, 'left');
        y = gsap.getProperty(el, 'top');
        zIndex = gsap.getProperty(el, 'zIndex');
      }
    }

    return { x, y, zIndex };
  };

  useLayoutEffect(() => {
    const scrollOverride = e => e.preventDefault();

    const openMenu = () => {
      // prevents user interaction during animation
      isMenuIsAnimating.current = true;
      gsap.delayedCall(0.7, () => {
        isMenuIsAnimating.current = false;
      });
      // intercept scroll events
      document.body.addEventListener('scroll', scrollOverride);
      // animate menu to open state
      return MenuOpenAnimation(navRef);
    };

    const closeMenu = () => {
      // skip close animation on first render
      if (firstRenderRef.current) {
        firstRenderRef.current = false;
        return;
      }

      isMenuIsAnimating.current = true;

      // animate menu to close state
      gsap.delayedCall(0.7, () => {
        // stop intercepting body scroll events
        document.body.removeEventListener('scroll', scrollOverride);
        document.body.classList.remove('overflow-hidden', 'w-screen');
        // refresh scrollTrigger timeline to update based on current scroll position
        if (navigationTimelineRef.current?.scrollTrigger) {
          navigationTimelineRef.current.scrollTrigger.refresh();
        }
        isMenuIsAnimating.current = false;
      });
      // using return help garbage collection handle timeline removal
      // eslint-disable-next-line consistent-return
      return MenuCloseAnimation(navRef, isDarkMode, resize);
    };

    const hamburgerOpen = () => {
      // store pre-menu-open color, so can be reassigned on close.
      const preOpenColor = document.querySelector('html').style.getPropertyValue('--nav-color');
      const headerEl = document.getElementsByClassName('MagHeader')[0];
      headerEl.dataset.preMenuOpenNavColor = preOpenColor;

      // animate-in menu button
      gsap.to('html', { duration: 0.45, '--nav-color': '#403e3d' });
      gsap.to(hamburgerTopRef.current, 0.45, {
        rotation: 45,
        y: 0,
        ease: 'power2.inOut',
      });
      gsap.to(hamburgerBottomRef.current, 0.45, {
        rotation: -45,
        y: 0,
        ease: 'power2.inOut',
      });
    };

    const hamburgerClose = () => {
      // in case resize while open changes origin nav color
      if (isDarkMode) {
        // return nav color to pre-menu-open color
        const headerEl = document.getElementsByClassName('MagHeader')[0];
        const preOpenColor = headerEl.dataset.preMenuOpenNavColor;
        if (preOpenColor && window.innerWidth <= DESKTOP_BREAKPOINT && pageType === 'article') {
          gsap.to('html', { duration: 0.5, '--nav-color': '#fffbf8' });
        }
      }
      // animate-in menu button
      gsap.to(hamburgerTopRef.current, 0.45, {
        rotation: 0,
        y: 4,
        ease: 'power2.inOut',
      });
      gsap.to(hamburgerBottomRef.current, 0.45, {
        rotation: 0,
        y: -4,
        ease: 'power2.inOut',
      });
    };

    if (isNavOpen) {
      openMenu();
      hamburgerOpen();
    } else {
      closeMenu();
      hamburgerClose();
    }
  }, [isNavOpen]);

  return (
    <>
      <div ref={navRef} className="Menu w-screen">
        <div className="Menu__UpperSpacer" />
        <nav className="ws-container--magazine py-xl md:py-2x lg:py-0">
          <div className="flex justify-between">
            <div className="Menu__Column hidden lg:flex items-center justify-center w-1/2">
              <div className="relative w-3/4">
                {CATEGORIES.map((category, index) => {
                  const zIndices = [24, 23, 22, 21];
                  const { x, y, zIndex } = getCategoryImageStyles(index);
                  return (
                    <div
                      ref={categoryImages[index]}
                      key={category}
                      data-category={category}
                      className={`Menu__CategoryImages w-full left-0 top-0 ${
                        index < CATEGORIES.length - 1 ? 'absolute' : 'relative'
                      }`}
                      style={{
                        left: x,
                        top: y,
                        zIndex: zIndex || zIndices[CATEGORIES.length - index],
                        aspectRatio: '1/1',
                      }}
                    >
                      <NextImage
                        className="h-full"
                        src={menuImagesByCategory[category].url}
                        width={800}
                        height={800}
                        alt=""
                        style={{ objectFit: 'cover' }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="Menu__Column w-full lg:w-1/2 flex items-center">
              <div className="Menu__Links w-full text-center lg:text-left">
                <EducationalLinks
                  className=""
                  categoryLinks={content.categories}
                  learnLinks={content.learn}
                  onHoverCallback={onCategoryHover}
                  menuLayout
                />
                <div className="block lg:hidden text-center">
                  <div className="mb-md mt-xl sm:mb-lg sm:mt-xxl h-px w-full bg-greyscale-black opacity-25 MenuMobileAnimation__Step" />
                  <SocialMedia className="MenuMobileAnimation__Step" />
                  <div className="mt-md mb-xl sm:mt-lg h-px w-full bg-greyscale-black opacity-25 MenuMobileAnimation__Step" />
                </div>
                <div className="block lg:hidden text-center">
                  <ReadAboutUs
                    label={aboutContent.link.label}
                    url={aboutContent.link.url}
                    description={aboutContent.description}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="Menu__Social MenuDesktopAnimation__Step hidden lg:block text-center">
            <SocialMedia />
          </div>
        </nav>
      </div>
      <header className="Header MagHeader w-screen fixed" role="banner">
        <div className="MagHeader__Background absolute w-screen top-0 left-0">
          <div className="MagHeader__BackgroundShadow" />
        </div>
        {
          // element stack used to retain margins on menu open by
          // measuring in js, since they are % based and change with width.
        }
        <div className="HeaderMeasurer ws-container--magazine relative h-full">
          <div className="HeaderSizer flex justify-between items-center relative h-full">
            <UnderlineButton
              className="MagHeader__GoToWealthsimple text-xs absolute hidden opacity-0 inline-block uppercase"
              linkText={translate('go-to-ws', locale)}
              linkUrl={`/${locale}`}
            />
            <UnderlineButton
              className="MagHeader__StartInvesting relative hidden sm:inline-block text-xs uppercase"
              linkText={translate('start-investing', locale)}
              linkUrl={`/${locale}`}
            />
            <SmartLink href="/magazine" aria-label="https://www.wealthsimple.com/magazine">
              <div className="WsLogo inline-block absolute h-full">
                <IconWealthsimple className="WsLogo__Wealthsimple" />
                <IconMagazine className="WsLogo__Magazine" />
              </div>
            </SmartLink>
            <div className="flex items-center">
              <UnderlineButton
                className="MagHeader__AboutMagazine invisible opacity-0 relative sm:inline-block hidden text-xs uppercase mr-md"
                linkText={content.about?.link?.underlineLabel}
                linkUrl={content.about?.link?.url}
              />
              <button
                className="HamburgerButton border-0 flex items-center"
                type="button"
                aria-label={`Navigation menu is ${isNavOpen ? 'open' : 'closed'}`}
                aria-expanded={isNavOpen}
                title="Navigation menu toggle"
                onClick={toggleMenu}
              >
                <p className="MagHeader__MenuLabel relative text-xs uppercase font-medium tracking-doublewide">
                  <span className="hidden sm:inline-block">{translate('menu', locale)}</span>
                </p>
                <div className="Hamburger relative">
                  <div ref={hamburgerTopRef} className="Hamburger__Top absolute w-full" />
                  <div ref={hamburgerBottomRef} className="Hamburger__Bottom absolute w-full" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
