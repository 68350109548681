'use client';

import React, { useRef, useLayoutEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Markdown } from 'src/tapestry/core/markdown';
import { useTranslationContext, useTranslation } from 'src/components/contexts/TranslationContext';
import { Image } from 'src/components/common/Image';
import { EducationalLinks } from '../EducationalLinks';
import { MagazineFooterTLDRSignup } from './components/MagazineFooterTLDRSignup/MagazineFooterTLDRSignup.component';
import { ReadAboutUs } from '../ReadAboutUs';
import { SocialMedia } from '../SocialMedia';
import { translations } from './MagazineFooter.translations';
import backToTopFr from './media/back-to-top-fr.png';
import backToTopEn from './media/back-to-top-en.png';
import './MagazineFooter.scss';

gsap.registerPlugin(ScrollTrigger);

export const MagazineFooter = () => {
  const { isFr } = useTranslationContext();
  const backToTopButtonRef = useRef();
  const onBackToTopClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);

  useLayoutEffect(() => {
    let tl;

    const onResize = () => {
      if (tl && tl.scrollTrigger) {
        tl.scrollTrigger.kill();
        tl.kill();
      }

      // prevent animations on mobile and reset values to origin.
      if (window.innerWidth < 1280) {
        gsap.set(
          ['.FooterAnimation__Left', '.FooterAnimation__Right', '.MagazineFooter__Disclosure'],
          {
            autoAlpha: 1,
            y: 0,
          }
        );
        return;
      }

      tl = gsap.timeline({
        duration: 1,
        scrollTrigger: {
          trigger: '.FooterSpacer',
          start: 'top bottom',
          end: `bottom bottom`,
          scrub: 0.25,
        },
      });

      const el = document.getElementsByClassName('MagazineFooter')[0];
      const rightSideEls = Array.from(
        el.getElementsByClassName('FooterAnimation__Right')
      ).reverse();
      const leftSideEls = Array.from(el.getElementsByClassName('FooterAnimation__Left')).reverse();

      tl.fromTo(
        '.MagazineFooter__Disclosure',
        {
          autoAlpha: 0,
          y: 60,
        },
        {
          duration: 1,
          autoAlpha: 1,
          y: 0,
          delay: 0.35,
        },
        0
      );
      tl.fromTo(
        leftSideEls,
        {
          autoAlpha: 0,
          y: 30,
        },
        {
          duration: 1,
          autoAlpha: 1,
          y: 0,
          stagger: 0.1,
          delay: 0.25,
        },
        0
      );
      tl.fromTo(
        rightSideEls,
        {
          autoAlpha: 0,
          y: 30,
        },
        {
          duration: 1,
          autoAlpha: 1,
          y: 0,
          stagger: 0.1,
          delay: 0.35,
        },
        0
      );
      tl.fromTo(
        backToTopButtonRef.current,
        {
          y: 75,
          autoAlpha: 0,
        },
        {
          duration: 1,
          delay: 0.5,
          y: 0,
          rotation: 180,
          autoAlpha: 1,
        },
        0
      );
    };

    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
      if (tl && tl.scrollTrigger) {
        tl.scrollTrigger.kill();
        tl.kill();
      }
    };
  }, []);

  return (
    <>
      <div className="FooterSpacer hidden lg:block min-h-screen" />
      <footer className="ws-container--magazine under MagazineFooter block lg:flex lg:flex-column lg:justify-end">
        <div className="lg:flex items-end text-left sm:text-center lg:text-left relative w-full">
          <button
            ref={backToTopButtonRef}
            className="MagazineFooter__BackToTop absolute hidden lg:block"
            onClick={onBackToTopClick}
            aria-hidden="true"
            tabIndex="-1"
            type="button"
          >
            <Image src={isFr ? backToTopFr : backToTopEn} alt="back to top icon" />
          </button>
          <div className="flex flex-col items-start sm:items-center lg:items-start lg:mb-md w-full lg:w-1/2">
            <MagazineFooterTLDRSignup className="FooterAnimation__Left" />
            <div className="block lg:hidden mt-xl mb-xl sm:mt-xxl sm:mb-xxl h-px w-full bg-greyscale-black opacity-25" />
            <div className="hidden lg:block text-left">
              <ReadAboutUs
                label={useTranslation('magazine-footer::about::link::label')}
                url={useTranslation('magazine-footer::about::link::url')}
                description={useTranslation('magazine-footer::about::description')}
              />
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <EducationalLinks
              categoryLinks={[
                {
                  url: useTranslation('magazine-footer::categories::news::url'),
                  label: useTranslation('magazine-footer::categories::news::label'),
                },
                {
                  url: useTranslation('magazine-footer::categories::diaries::url'),
                  label: useTranslation('magazine-footer::categories::diaries::label'),
                },
                {
                  url: useTranslation('magazine-footer::categories::finance::url'),
                  label: useTranslation('magazine-footer::categories::finance::label'),
                },
                {
                  url: useTranslation('magazine-footer::categories::world::url'),
                  label: useTranslation('magazine-footer::categories::world::label'),
                },
              ]}
              learnLinks={[
                {
                  url: useTranslation('magazine-footer::learn::learn::url'),
                  label: useTranslation('magazine-footer::learn::learn::label'),
                },
                {
                  url: useTranslation('magazine-footer::learn::calculator::url'),
                  label: useTranslation('magazine-footer::learn::calculator::label'),
                },
              ]}
            />
            <div className="block lg:hidden text-center">
              <div className="mb-md mt-xl sm:mb-lg sm:mt-xxl h-px w-full bg-greyscale-black opacity-25" />
              <SocialMedia />
              <div className="mt-md mb-xl sm:mt-lg h-px w-full bg-greyscale-black opacity-25" />
            </div>
            <div className="block lg:hidden text-center">
              <ReadAboutUs
                label={useTranslation('magazine-footer::about::link::label')}
                url={useTranslation('magazine-footer::about::link::url')}
                description={useTranslation('magazine-footer::about::description')}
              />
            </div>
          </div>
        </div>
        <p className="MagazineFooter__Disclosure text-xxs text-body">
          <Markdown
            source={useTranslation('magazine-footer::disclosure')}
            unwrapElements="paragraph"
          />
        </p>
      </footer>
    </>
  );
};
