const navContent = {
  'en-ca': {
    about: {
      link: {
        url: '/magazine/about',
        label: 'READ MORE ABOUT WEALTHSIMPLE',
        underlineLabel: 'ABOUT MAGAZINE',
      },
      description:
        'Wealthsimple Magazine tells compelling, thoughtful, and unique stories about money through the lens of local, creative, and influential people.',
    },
    learn: [
      {
        url: '/learn',
        label: 'Personal Finance 101',
      },
      {
        url: '/tool/retirement-calculator',
        label: 'Retirement Calculator',
      },
    ],
    categories: [
      {
        url: '/magazine/news',
        hover: 'The latest news from Wealthsimple',
        label: 'News',
      },
      {
        url: '/magazine/money-diaries',
        hover: 'Candid money stories from interesting people',
        label: 'Money Diaries',
      },
      {
        url: '/magazine/finance-for-humans',
        hover: 'How to be a better money person',
        label: 'Finance for Humans',
      },
      {
        url: '/magazine/money-and-the-world',
        hover: 'How money shapes the world we live in',
        label: 'Money & the World',
      },
    ],
  },
  'fr-ca': {
    about: {
      link: {
        url: '/magazine/about',
        label: 'EN SAVOIR PLUS SUR NOUS',
        underlineLabel: 'ABOUT MAGAZINE',
      },
      description:
        'Le magazine Wealthsimple. Des personnalités locales, créatives et influentes. Une discussion sur l’argent éclairée et différente. Une lecture captivante.',
    },
    learn: [
      {
        url: '/learn',
        label: 'Finances personelles 101',
      },
    ],
    categories: [
      {
        url: '/magazine/news',
        hover: 'Les dernières nouvelles de Wealthsimple',
        label: 'Nouvelles',
      },
      {
        url: '/magazine/money-diaries',
        hover:
          ' Des personnalités fascinantes qui nous parlent sans  détour de leur relation avec l’argent',
        label: 'Tranches de view financière',
      },
      {
        url: '/magazine/finance-for-humans',
        hover: 'Comment mieux gérer son argent',
        label: 'Finance pour humains',
      },
      {
        url: '/magazine/money-and-the-world',
        hover: 'Comment l’argent façonne le monde dans lequel nous vivons',
        label: "L'argent et le monde",
      },
    ],
  },
};

export { navContent };
