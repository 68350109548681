import gsap from 'gsap';

const MenuOpenAnimation = navRef => {
  const tl = gsap.timeline();

  // initially hide marked menu animation elements
  const animationClass =
    window.innerWidth < 1280 ? '.MenuMobileAnimation__Step' : '.MenuDesktopAnimation__Step';
  tl.set(animationClass, { autoAlpha: 0, ease: 'power2.inOut' }, 0.0);
  tl.set('.Menu__CategoryImages', { autoAlpha: 0, y: 10, scale: 1 });

  // hide nav under-shadow
  tl.to(
    '.MagHeader__BackgroundShadow',
    {
      duration: 0.25,
      opacity: 0,
    },
    0
  );

  // menu reveal
  tl.to(
    navRef.current,
    {
      duration: 0.3,
      autoAlpha: 1,
      y: 0,
      ease: 'power2.in',
      onComplete: () => {
        document.body.classList.add('overflow-hidden', 'w-screen');
      },
    },
    0.25
  );

  // animate-out menu closed elements
  tl.to('.WsLogo', { duration: 0.35, y: -6, autoAlpha: 0, ease: 'power2.inOut' }, 0);
  tl.to(
    '.MagHeader__StartInvesting',
    {
      duration: 0.35,
      x: 3,
      autoAlpha: 0,
      ease: 'power2.inOut',
    },
    0
  );

  // lower resolutions don't show/hide menu label
  if (window.innerWidth >= 768) {
    gsap.fromTo(
      '.MagHeader__MenuLabel',
      { display: 'block' },
      {
        duration: 0.35,
        x: -5,
        autoAlpha: 0,
        onComplete: () => {
          // need to remove from DOM for AboutMagazine link to take MenuLabel's localtion
          gsap.set('.MagHeader__MenuLabel', { display: 'none' });
        },
      },
      0.35
    );
  }

  // reveal marked menu animation elements
  const isDesktop = animationClass === '.MenuDesktopAnimation__Step';
  tl.fromTo('.Menu__Links', { y: -20, opacity: 1, scale: 1 }, { duration: 1.35, y: 0 }, 0.75);
  tl.to(
    animationClass,
    {
      duration: !isDesktop ? 0.4 : 0.65,
      y: 0,
      autoAlpha: 1,
      stagger: !isDesktop ? 0.1 : 0.15,
      ease: 'power2.inOut',
    },
    0.55
  );

  // animate-in menu open header elements
  tl.fromTo(
    '.MagHeader__AboutMagazine',
    { x: -10, autoAlpha: 0 },
    { duration: 0.35, autoAlpha: 1, x: 0 },
    0.5
  );
  tl.fromTo(
    '.MagHeader__GoToWealthsimple',
    { x: 10, y: 2 },
    {
      duration: 0.35,
      x: 0,
      opacity: 1,
      ease: 'power2.inOut',
      display: 'inline-block',
    },
    0.5
  );

  // only animate-in images on desktop
  if (isDesktop) {
    tl.fromTo(
      '.Menu__CategoryImages',
      { scale: 0.95 },
      { duration: 0.2, autoAlpha: 1, y: 0, stagger: 0.175, scale: 1, ease: 'power2.inOut' },
      1.5
    );
  }
  return tl;
};

export default MenuOpenAnimation;
