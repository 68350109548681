import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { isTabletScreen, isMobileScreen } from 'src/utils/window';

gsap.registerPlugin(ScrollTrigger);

const LOGO_POSITIONS = {
  singleLine: {
    ws: { x: -106, y: -12 },
    mag: { x: 22, y: -12 },
  },
  doubleLineCentered: {
    ws: { x: -71, y: -18 },
    mag: { x: -51, y: 1 },
  },
  doubleLineLeft: {
    ws: { x: 0, y: -18 },
    mag: { x: 0, y: 1 },
  },
};

const MobileCategoryAnimation = (pageType, darkMode = false, isOpen = false) => {
  // base settings
  gsap.set('.MagHeader', { height: 64, width: '100%', marginLeft: '0%', position: 'fixed' }, 0);
  gsap.set('.HeaderSizer', { marginLeft: 0, width: '100%' });
  if (isTabletScreen()) {
    gsap.set('.WsLogo__Wealthsimple', LOGO_POSITIONS.doubleLineLeft.ws);
    gsap.set('.WsLogo__Magazine', LOGO_POSITIONS.doubleLineLeft.mag);
  } else {
    gsap.set('.WsLogo__Wealthsimple', LOGO_POSITIONS.doubleLineCentered.ws);
    gsap.set('.WsLogo__Magazine', LOGO_POSITIONS.doubleLineCentered.mag);
  }

  // scroll animation
  const tl = gsap.timeline({
    scrollTrigger: {
      startTrigger: document.body,
      start: 'top top',
      end: 'top top-=500',
      scrub: 1,
    },
  });

  // fade-in background when IG Viewer or article
  if ((pageType === 'index' && isMobileScreen()) || pageType === 'article') {
    tl.fromTo('.MagHeader__Background', { opacity: 0 }, { duration: 0.25, opacity: 1 }, 0);
  } else {
    tl.set('html', { '--nav-color': '#403e3d' }, 0);
    gsap.set('.MagHeader__Background', { opacity: 1 }, 0);
  }

  // articles are always one color to black, depending on hero image background color
  if (pageType === 'article') {
    const originColor = darkMode ? '#fffbf8' : '#403e3d';
    tl.fromTo(
      'html',
      { '--nav-color': originColor },
      { '--nav-color': '#403e3d', duration: 0.5 },
      0
    );
  }

  if (isOpen) {
    gsap.set('html', { '--nav-color': '#403e3d' });
  }

  // Nav color animation for ImageProgressor is handled in that component because
  // color is constantly changing and need origin color to reset tween.

  // reveal under-shadow
  tl.fromTo(
    '.MagHeader__BackgroundShadow',
    { opacity: 0 },
    {
      duration: 0.5,
      opacity: 1,
    },
    0
  );

  return tl;
};

export default MobileCategoryAnimation;
