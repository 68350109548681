import gsap from 'gsap';

const MenuCloseAnimation = (navRef, isDarkMode = false, resizeFn) => {
  const tl = gsap.timeline();
  tl.to(
    '.MagHeader__GoToWealthsimple',
    {
      duration: 0.35,
      opacity: 0,
      ease: 'power2.inOut',
    },
    0
  );
  tl.to(
    ['.Menu__Links', '.Menu__CategoryImages'],
    {
      duration: 0.3,
      opacity: 0,
      scale: 0.98,
      ease: 'power2.in',
    },
    0
  );
  tl.to('.MagHeader__AboutMagazine', { duration: 0.35, autoAlpha: 0, x: -5 }, 0);
  tl.to(
    navRef.current,
    0.3,
    {
      autoAlpha: 0,
      y: 5,
      ease: 'power2.in',
    },
    0.4
  );

  tl.set('.MagHeader__GoToWealthsimple', { display: 'none' }, 0.35);

  // darkmode has unique layout
  if (isDarkMode) {
    // nav has two widths depending on scroll position
    if (
      window.innerWidth > 960 &&
      Math.abs(document.body.getBoundingClientRect().top) < window.innerHeight
    ) {
      // mimics the margin in the ws-container--magazine class
      const leftMargin = gsap.getProperty('.HeaderMeasurer', 'paddingLeft');
      const width = window.innerWidth * 0.5 - leftMargin * 2;
      const marginLeft = `calc(50% + ${leftMargin}px)`;
      tl.set('.HeaderSizer', { marginLeft, width }, 0.35);
    } else if (resizeFn) {
      // trigger new scroll animation instance
      gsap.delayedCall(0.95, resizeFn(window.innerWidth));
    }
  }

  tl.set('.HeaderSizer', { background: 'rgba(255, 251, 248, 0)' }, 0.35);

  tl.to(
    '.MagHeader__MenuLabel',
    {
      display: 'block',
      duration: 0.35,
      x: 0,
      autoAlpha: 1,
      ease: 'power2.inOut',
    },
    0.6
  );
  tl.fromTo(
    '.MagHeader__StartInvesting',
    { x: 10, autoAlpha: 0 },
    { duration: 0.35, x: 0, autoAlpha: 1, ease: 'power2.inOut' },
    0.6
  );
  tl.to(
    '.WsLogo',
    {
      duration: 0.35,
      y: 0,
      autoAlpha: 1,
      ease: 'power2.out',
    },
    0.6
  );
  return tl;
};

export default MenuCloseAnimation;
