import React from 'react';
import { SmartLink } from 'src/tapestry/core/links/smart-link';

const LINKS = [
  {
    url: 'https://www.instagram.com/wealthsimple/',
    label: 'INSTAGRAM',
  },
  {
    url: 'https://twitter.com/wealthsimple',
    label: 'TWITTER',
  },
  {
    url: 'https://www.youtube.com/wealthsimple',
    label: 'YOUTUBE',
  },
];

export const SocialMedia = ({ className = '' }) => {
  return (
    <ul className={`list-none SocialLinks ${className}`}>
      {LINKS.map(link => {
        return (
          <li
            key={link.label}
            className="inline-block px-xs text-xs sm:text-md text-greyscale-black tracking-doublewide"
          >
            <SmartLink className="no-underline" href={link.url}>
              {link.label}
            </SmartLink>
          </li>
        );
      })}
    </ul>
  );
};
