import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const LOGO_POSITIONS = {
  singleLine: {
    ws: { x: -120, y: -7 },
    mag: { x: 22, y: -7 },
  },
  doubleLineCentered: {
    ws: { x: -71, y: -20 },
    mag: { x: -51, y: 3 },
  },
  doubleLineLeft: {
    ws: { x: 0, y: -26 },
    mag: { x: 0, y: -5 },
  },
};

const MENU_HEIGHT = 100;

const DesktopCategoryAnimation = (articleMenu = false) => {
  gsap.set('html', { '--nav-color': '#403e3d' });
  gsap.set('.WsLogo__Wealthsimple', {
    x: LOGO_POSITIONS.doubleLineCentered.ws.x,
    y: LOGO_POSITIONS.doubleLineCentered.ws.y,
  });
  gsap.set('.WsLogo__Magazine', {
    x: LOGO_POSITIONS.doubleLineCentered.mag.x,
    y: LOGO_POSITIONS.doubleLineCentered.mag.y,
  });

  const tl = gsap.timeline({
    scrollTrigger: {
      startTrigger: '.ArticleHero',
      start: 'top top',
      end: 'top top-=1000',
      scrub: 1,
    },
  });

  const originOpacity = articleMenu ? 0 : 1;
  tl.fromTo(
    '.MagHeader__Background',
    { opacity: originOpacity },
    {
      duration: 0.25,
      opacity: 1,
    },
    0
  );

  // reveal under-shadow
  tl.fromTo('.MagHeader__BackgroundShadow', { opacity: 0 }, { duration: 0.5, opacity: 1 }, 0);

  // hide magazine logo
  tl.to(
    '.WsLogo__Wealthsimple',
    {
      duration: 0.3,
      y: LOGO_POSITIONS.doubleLineCentered.ws.y - 5,
      opacity: 0,
      ease: 'Power2.ease.in',
    },
    0.5
  );

  // hide magazine logo
  tl.to(
    '.WsLogo__Magazine',
    {
      duration: 0.3,
      y: LOGO_POSITIONS.doubleLineCentered.mag.y - 5,
      opacity: 0,
      ease: 'Power2.ease.in',
    },
    0.6
  );

  // shrink header height
  tl.fromTo(
    '.MagHeader',
    { height: MENU_HEIGHT },
    { duration: 0.35, height: 64, ease: 'Power2.ease.inOut' },
    0.85
  );

  // prepare for single line logo reveal
  tl.set(
    '.WsLogo__Magazine',
    { x: LOGO_POSITIONS.singleLine.mag.x, y: LOGO_POSITIONS.singleLine.mag.y + 5 },
    0.89
  );

  // prepare for single line logo reveal
  tl.set(
    '.WsLogo__Wealthsimple',
    { x: LOGO_POSITIONS.singleLine.ws.x, y: LOGO_POSITIONS.singleLine.ws.y + 5 },
    0.8
  );

  // reveal mag logo in single line
  tl.to(
    '.WsLogo__Magazine',
    {
      duration: 0.3,
      x: LOGO_POSITIONS.singleLine.mag.x,
      y: LOGO_POSITIONS.singleLine.ws.y,
      ease: 'Power2.ease.out',
      opacity: 1,
    },
    1.05
  );

  // reveal ws logo in single line
  tl.to(
    '.WsLogo__Wealthsimple',
    {
      duration: 0.3,
      x: LOGO_POSITIONS.singleLine.ws.x,
      y: LOGO_POSITIONS.singleLine.ws.y,
      ease: 'Power2.ease.out',
      opacity: 1,
    },
    0.95
  );

  return tl;
};

export default DesktopCategoryAnimation;
