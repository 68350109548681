import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const LOGO_POSITIONS = {
  singleLine: {
    ws: { x: -120, y: -7 },
    mag: { x: 22, y: -7 },
  },
  doubleLineCentered: {
    ws: { x: -71, y: -20 },
    mag: { x: -51, y: 3 },
  },
  doubleLineLeft: {
    ws: { x: 0, y: -26 },
    mag: { x: 0, y: -5 },
  },
};

const MENU_HEIGHT = 100;

const DesktopArticleDarkAnimation = (isOpen = false) => {
  // initial settings - good place to override last settings on a resize
  gsap.set('html', { '--nav-color': '#403e3d' });
  gsap.set('.WsLogo__Wealthsimple', LOGO_POSITIONS.doubleLineCentered.ws);
  gsap.set('.WsLogo__Magazine', LOGO_POSITIONS.doubleLineCentered.mag);
  gsap.set('.MagHeader__Background', { opacity: 0 });
  gsap.set('.MagHeader', {
    height: MENU_HEIGHT,
    top: 0,
    position: 'absolute',
  });

  // mimics the margin in ws-container--magazine
  const leftMargin = gsap.getProperty('.HeaderMeasurer', 'paddingLeft');
  const width = window.innerWidth * 0.5 - leftMargin * 2;
  const marginLeft = `calc(50% + ${leftMargin}px)`;

  if (!isOpen) {
    gsap.set('.HeaderSizer', { marginLeft, width });
  }

  // hides half menu and reveals full height menu after article hero
  const menuSwapTl = gsap.timeline(
    {
      duration: 1,
      scrollTrigger: {
        trigger: '.ArticleHero',
        start: `bottom top+=${MENU_HEIGHT}`,
        end: `bottom top-=${MENU_HEIGHT}`,
        scrub: 0.25,
      },
    },
    0
  );

  // dark menu initially starts at halfwidth
  menuSwapTl.set('.HeaderSizer', { marginLeft, width }, 0);

  menuSwapTl.set(
    '.MagHeader',
    { height: 64, width: '100%', marginLeft: '0%', y: -64, position: 'fixed' },
    0.49
  );
  menuSwapTl.set('.WsLogo__Wealthsimple', LOGO_POSITIONS.singleLine.ws, 0.5);
  menuSwapTl.set('.WsLogo__Magazine', LOGO_POSITIONS.singleLine.mag, 0.5);
  menuSwapTl.set('.MagHeader__Background', { opacity: 1 }, 0.5);
  menuSwapTl.set('.HeaderSizer', { width: '100%', marginLeft: 0 }, 0.5);
  // show menu and under-shadow
  menuSwapTl.to('.MagHeader', { duration: 0.49, y: 0, ease: 'linear' }, 0.5);
  menuSwapTl.to(
    '.MagHeader__BackgroundShadow',
    { duration: 0.5, opacity: 1, ease: 'Power2.ease.inOut' },
    0.5
  );
  // using separate timeline for iniital shadow fade-in
  const shadowStartTl = gsap.timeline({
    scrollTrigger: {
      trigger: '.ArticleHero',
      start: `bottom bottom`,
      end: `bottom bottom-=600`,
      scrub: 1,
    },
  });
  shadowStartTl.fromTo(
    '.MagHeader__BackgroundShadow',
    { opacity: 0 },
    { duration: 1.5, opacity: 1, ease: 'Power2.ease.inOut' },
    0
  );

  return {
    shadowStartTl,
    menuSwapTl,
  };
};

export default DesktopArticleDarkAnimation;
