'use client';

import React from 'react';
import PropTypes from 'prop-types';
import { TLDRSubscribeForm } from 'src/components/common/TLDRSubscribeForm';
import { classNames } from 'src/utils/css';
import { useTranslationContext, useTranslation } from 'src/components/contexts/TranslationContext';
import { Image } from 'src/components/common/Image';
import { translations } from './MagazineFooterTLDRSignup.translations';
import {
  magazineFooterTLDRSignup_,
  tldrLogo_,
  tldrForm_,
} from './MagazineFooterTLDRSignup.module.scss';
import tldr from './media/tldr.png';

const MagazineFooterTLDRSignup = ({ className }) => {
  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);

  return (
    <div className={classNames(magazineFooterTLDRSignup_, className)}>
      <Image className={tldrLogo_} src={tldr} alt="TLDR Newsletter Logo" />
      <p className="ws-text-lg">{useTranslation('magazine_footer_tldr::subtitle')}</p>
      <TLDRSubscribeForm
        className={tldrForm_}
        buttonLabel={useTranslation('magazine_footer_tldr::button')}
      />
    </div>
  );
};

MagazineFooterTLDRSignup.propTypes = {
  className: PropTypes.string,
};

export { MagazineFooterTLDRSignup };
