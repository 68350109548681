export const translations = {
  'en-ca': {
    'magazine-footer::about::link::label': 'READ MORE ABOUT US',
    'magazine-footer::about::link::url': '/magazine/about',
    'magazine-footer::about::description':
      'Wealthsimple Magazine tells compelling, thoughtful, and unique stories about money through the lens of local, creative, and influential people.',
    'magazine-footer::categories::news::url': '/magazine/news',
    'magazine-footer::categories::news::label': 'News',
    'magazine-footer::categories::diaries::url': '/magazine/money-diaries',
    'magazine-footer::categories::diaries::label': 'Money Diaries',
    'magazine-footer::categories::finance::url': '/magazine/finance-for-humans',
    'magazine-footer::categories::finance::label': 'Finance for Humans',
    'magazine-footer::categories::world::url': '/magazine/money-and-the-world',
    'magazine-footer::categories::world::label': 'Money & the World',
    'magazine-footer::learn::learn::url': '/learn',
    'magazine-footer::learn::learn::label': 'Personal Finance 101',
    'magazine-footer::learn::calculator::url': '/tool/retirement-calculator',
    'magazine-footer::learn::calculator::label': 'Retirement Calculator',
    'magazine-footer::disclosure':
      'The content on this site is produced by Wealthsimple Technologies Inc. and is for informational purposes only. The content is not intended to be investment advice or any other kind of professional advice. Before taking any action based on this content you should consult a professional. We do not endorse any third parties referenced on this site. When you invest, your money is at risk and it is possible that you may lose some or all of your investment. Past performance is not a guarantee of future results. Historical returns, hypothetical returns, expected returns and images included in this content are for illustrative purposes only. By using this website, you accept our ([Terms of Use](https://www.wealthsimple.com/en-ca/legal/terms "Terms of Use")) and ([Privacy Policy](https://www.wealthsimple.com/en-ca/legal/privacy "Privacy Policy")). Copyright {{CURRENT_YEAR}} Wealthsimple Technologies Inc.',
  },
  'fr-ca': {
    'magazine-footer::about::link::label': 'EN SAVOIR PLUS SUR NOUS',
    'magazine-footer::about::description':
      'Le magazine Wealthsimple. Des personnalités locales, créatives et influentes. Une discussion sur l’argent éclairée et différente. Une lecture captivante.',
    'magazine-footer::categories::news::label': 'Nouvelles',
    'magazine-footer::categories::diaries::label': 'Tranches de vie financière',
    'magazine-footer::categories::finance::label': 'Finance pour humains',
    'magazine-footer::categories::world::label': "L'argent et le monde",
    'magazine-footer::learn::class::label': 'Les Classes de Maître',
    'magazine-footer::learn::learn::label': 'Finances Personnelles 101',
    'magazine-footer::disclosure':
      'Le contenu de ce site est produit par Wealthsimple Technologies inc. et est publié à titre informatif seulement. Il ne doit pas être considéré comme des conseils en matière de placement, ni comme aucun autre type de conseil professionnel. Avant de prendre une décision en fonction de ce que vous lisez sur ce site, consultez un professionnel. La mention de tiers sur ce site ne doit pas être interprétée comme un cautionnement. L’argent placé est exposé à des risques, et il n’est pas exclu qu’il soit perdu, en partie ou en totalité. Les rendements antérieurs ne sont pas garants des rendements futurs. Les références à des rendements antérieurs, hypothétiques ou anticipés et les illustrations sont fournies à titre indicatif seulement. En utilisant ce site Web, vous acceptez nos [Conditions d’utilisation](https://www.wealthsimple.com/fr-ca/legal/terms "Conditions d\'utilisation") et notre [Politique de confidentialité](https://www.wealthsimple.com/fr-ca/legal/privacy "Politique de confidentialité"). Droits d’auteur {{CURRENT_YEAR}} Wealthsimple Technologies Inc.',
  },
};
