import React from 'react';

const IconMagazine = ({ className = '' }) => {
  return (
    <svg className={className} viewBox="0 0 75 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.03372 8.12788L5.50797 1.4446H0.349956V1.60335C1.08029 1.65097 1.44546 1.74623 1.79541 1.96847C2.26709 2.28597 2.35838 2.61934 2.35838 3.90519V7.25477C2.35838 9.00099 2.22144 9.8741 1.85627 10.5885C1.47589 11.3346 0.958569 11.6997 0 11.8743V12.0331H4.82328V11.8743C3.91035 11.7632 3.49953 11.5092 3.11914 10.8583C2.72354 10.144 2.55618 9.14386 2.55618 7.52464V2.12722L6.57304 12.5569L10.5899 1.8891V9.31849C10.5899 10.7948 10.5443 11.0965 10.316 11.4298C10.1182 11.7156 9.84434 11.8267 9.17486 11.8743V12.0331H15.0024V11.8743C14.3329 11.8267 14.0742 11.7156 13.8612 11.4298C13.633 11.0965 13.5873 10.7948 13.5873 9.31849V4.15919C13.5873 2.68283 13.633 2.38121 13.8612 2.04784C14.0742 1.7621 14.3329 1.65097 15.0024 1.60335V1.4446H10.5595L8.03372 8.12788Z"
        fill="#2A2A2A"
      />
      <path
        d="M24.385 10.8583C24.0959 11.0171 23.959 11.0647 23.7764 11.0647C23.5177 11.0647 23.2895 10.9377 23.2134 10.7313C23.1221 10.5408 23.1069 10.3345 23.1069 9.73123V6.08003C23.1069 5.17517 22.9548 4.69893 22.5135 4.33381C22.1636 4.01631 21.7375 3.88932 21.1289 3.88932C19.8204 3.88932 17.9946 4.41318 16.8382 5.0958C15.9405 5.63554 15.5297 6.19116 15.5297 6.84202C15.5297 7.49289 15.9709 7.95325 16.5947 7.95325C17.462 7.95325 18.0858 7.31826 18.2076 6.31815C18.3293 5.31804 18.3445 5.2228 18.4815 4.93705C18.664 4.54018 19.0749 4.30206 19.5161 4.30206C19.8204 4.30206 20.0486 4.42906 20.216 4.69893C20.3529 4.95293 20.4138 5.44504 20.4138 6.49278V7.31826C19.3183 7.65163 18.3749 8.00088 17.6142 8.33425C16.047 9.04861 15.5145 9.58835 15.5145 10.4932C15.5145 11.5251 16.3969 12.2394 17.675 12.2394C18.877 12.2394 19.6835 11.7949 20.4138 10.7313C20.6116 11.7632 21.0985 12.2394 21.9505 12.2394C22.7113 12.2394 23.4264 11.8743 24.4763 10.9695L24.385 10.8583ZM20.4138 9.96935C20.4138 10.6996 20.0638 11.16 19.5009 11.16C18.9227 11.16 18.6184 10.6361 18.6184 9.68361C18.6184 8.47712 19.0292 7.96913 20.4138 7.50876V9.96935Z"
        fill="#2A2A2A"
      />
      <path
        d="M28.9072 3.88932H28.755V3.74644C28.755 3.03208 28.9072 2.73046 29.2724 2.73046C29.4397 2.73046 29.5463 2.80983 29.8506 3.1432C30.1701 3.49245 30.4287 3.61944 30.7635 3.61944C31.3569 3.61944 31.8285 3.0797 31.8285 2.39709C31.8285 1.65098 31.2351 1.07948 30.4592 1.07948C29.318 1.07948 28.5877 2.06372 28.5877 3.5877V3.88932C27.7204 3.93694 27.2183 4.04806 26.6401 4.33381C25.6815 4.79418 25.1338 5.47679 25.1338 6.20703C25.1338 7.04839 25.6359 7.66751 26.5945 8.00088C25.3772 8.68349 24.8295 9.38198 24.8295 10.2233C24.8295 11.033 25.4077 11.5886 26.488 11.8426C25.4533 12.2236 24.9055 12.7316 24.9055 13.3348C24.9055 14.2873 26.1989 14.8905 28.2834 14.8905C30.9765 14.8905 33.061 13.5253 33.061 11.7473C33.061 10.398 31.8894 9.44548 30.2614 9.44548C30.0027 9.44548 29.6223 9.46136 29.2419 9.47723C28.7855 9.50898 28.4355 9.52486 28.2073 9.52486C26.8988 9.52486 26.4271 9.30261 26.4271 8.66762C26.4271 8.4295 26.5032 8.27075 26.7618 8.06438C27.4161 8.27075 27.9486 8.366 28.5725 8.366C30.52 8.366 32.0568 7.33414 32.0568 6.03241C32.0568 5.46092 31.722 4.92118 31.1286 4.54018C30.5048 4.15919 29.7288 3.90519 29.0898 3.88932H28.9072ZM28.5572 4.09569C29.2419 4.09569 29.5767 4.81005 29.5767 6.27053C29.5767 7.61988 29.3028 8.15963 28.6333 8.15963C27.9334 8.15963 27.6139 7.47701 27.6139 5.96891C27.6139 4.66718 27.903 4.09569 28.5572 4.09569ZM26.8379 11.9061C27.3248 11.9854 27.5987 12.0013 28.0399 12.0013C28.2529 12.0013 28.5268 11.9854 28.8768 11.9696C29.1963 11.9537 29.5462 11.9378 29.6984 11.9378C30.7939 11.9378 31.4177 12.3506 31.4177 13.0967C31.4177 13.9857 30.4744 14.6048 29.1202 14.6048C27.5074 14.6048 26.3814 13.8587 26.3814 12.7951C26.3814 12.3823 26.5184 12.1283 26.8379 11.9061Z"
        fill="#2A2A2A"
      />
      <path
        d="M42.3493 10.8583C42.0602 11.0171 41.9232 11.0647 41.7406 11.0647C41.482 11.0647 41.2538 10.9377 41.1777 10.7313C41.0864 10.5408 41.0712 10.3345 41.0712 9.73123V6.08003C41.0712 5.17517 40.919 4.69893 40.4778 4.33381C40.1278 4.01631 39.7018 3.88932 39.0932 3.88932C37.7846 3.88932 35.9588 4.41318 34.8025 5.0958C33.9047 5.63554 33.4939 6.19116 33.4939 6.84202C33.4939 7.49289 33.9352 7.95325 34.559 7.95325C35.4263 7.95325 36.0501 7.31826 36.1718 6.31815C36.2935 5.31804 36.3088 5.2228 36.4457 4.93705C36.6283 4.54018 37.0391 4.30206 37.4804 4.30206C37.7847 4.30206 38.0129 4.42906 38.1803 4.69893C38.3172 4.95293 38.378 5.44504 38.378 6.49278V7.31826C37.2825 7.65163 36.3392 8.00088 35.5784 8.33425C34.0112 9.04861 33.4787 9.58835 33.4787 10.4932C33.4787 11.5251 34.3612 12.2394 35.6393 12.2394C36.8413 12.2394 37.6477 11.7949 38.378 10.7313C38.5758 11.7632 39.0627 12.2394 39.9148 12.2394C40.6756 12.2394 41.3907 11.8743 42.4406 10.9695L42.3493 10.8583ZM38.378 9.96935C38.378 10.6996 38.0281 11.16 37.4651 11.16C36.8869 11.16 36.5826 10.6361 36.5826 9.68361C36.5826 8.47712 36.9934 7.96913 38.378 7.50876V9.96935Z"
        fill="#2A2A2A"
      />
      <path
        d="M50.4039 4.09569H44.8807C44.2569 4.09569 44.0743 4.01631 43.8765 3.6512L42.8723 7.33414L43.0092 7.36589C43.6483 5.93716 45.4741 4.58781 47.2087 4.28619L42.5376 12.0331H48.0455C48.7911 12.0331 48.9737 12.0807 49.3997 12.3506L50.5408 8.14375L50.4039 8.09613C49.2932 10.2233 47.3304 11.8108 45.748 11.8426L50.4039 4.09569Z"
        fill="#2A2A2A"
      />
      <path
        d="M50.8542 5.27042C51.3715 5.30217 51.6454 5.42917 51.8584 5.73079C52.1019 6.08003 52.1627 6.44515 52.1627 7.39764V10.0011C52.1627 11.6203 52.041 11.8267 51.0368 11.8743V12.0331H55.9666V11.8743C54.9776 11.8267 54.8558 11.6045 54.8558 10.0011V3.88932C53.9885 4.23856 52.2388 4.7783 50.839 5.11167L50.8542 5.27042ZM53.3191 0C52.4366 0 51.7062 0.76199 51.7062 1.68273C51.7062 2.61934 52.4366 3.38132 53.3343 3.38132C54.2168 3.38132 54.9471 2.61933 54.9471 1.71447C54.9471 0.746113 54.232 0 53.3191 0Z"
        fill="#2A2A2A"
      />
      <path
        d="M60.0713 3.88932C58.5954 4.42906 57.439 4.79418 56.0544 5.11167L56.0848 5.27042C56.5869 5.30217 56.8608 5.42917 57.0738 5.73079C57.3173 6.06416 57.3781 6.44515 57.3781 7.39764V10.0011C57.3781 11.6203 57.2412 11.8267 56.1609 11.8743V12.0331H60.7712V11.8743C60.1473 11.7949 60.056 11.6044 60.0713 10.3186V5.65142C60.4821 5.27042 60.8472 5.0958 61.2124 5.0958C61.4863 5.0958 61.7754 5.25455 61.8819 5.49267C62.0188 5.76254 62.0492 6.04828 62.0492 7.31826V10.3186C62.0492 11.1282 62.034 11.287 61.958 11.4933C61.8667 11.7314 61.6993 11.8426 61.3493 11.8743V12.0331H65.6553V11.8743C64.8337 11.7949 64.7272 11.6203 64.7424 10.3186V6.20703C64.7424 5.36567 64.6511 4.9053 64.3924 4.55606C64.1033 4.12744 63.5252 3.88932 62.8405 3.88932C61.8362 3.88932 61.1059 4.28618 60.0713 5.39742V3.88932Z"
        fill="#2A2A2A"
      />
      <path
        d="M74.1815 7.30239C74.075 6.44515 73.8924 5.95304 73.4816 5.38154C72.7969 4.42906 71.6862 3.88932 70.4537 3.88932C68.141 3.88932 66.3151 5.77841 66.3151 8.1755C66.3151 10.4773 68.0497 12.2394 70.332 12.2394C71.5036 12.2394 72.5382 11.7473 73.2381 10.8583C73.6642 10.3186 73.9076 9.8741 74.2424 8.93749L74.1054 8.87399C73.4816 9.84235 72.7665 10.2551 71.6862 10.2551C69.9973 10.2551 69.1604 9.28673 69.1452 7.30239H74.1815ZM69.13 7.11189C69.13 5.17517 69.6017 4.09569 70.4233 4.09569C70.9406 4.09569 71.2297 4.42906 71.3667 5.19105C71.4427 5.60379 71.4732 6.09591 71.4732 7.11189H69.13Z"
        fill="#2A2A2A"
      />
    </svg>
  );
};

export default IconMagazine;
