import React from 'react';
import { Image } from 'src/components/common/Image';
import { UnderlineButton } from '../UnderlineButton';
import wealthsimpleMagSvg from './media/wealthsimple-magazine.svg';
import './ReadAboutUs.scss';

export const ReadAboutUs = ({ description, label, url, className = '' }) => {
  return (
    <div className={`ReadAboutUs ${className}`}>
      <Image
        className="ReadAboutUs__MagazineLogo block MenuMobileAnimation__Step FooterAnimation__Left"
        src={wealthsimpleMagSvg}
        alt="Wealthsimple magazine logo"
      />
      <p className="ReadAboutUs__Description mx-auto lg:mx:0 mb-sm text-xs opacity-75 text-greyscale-black MenuMobileAnimation__Step FooterAnimation__Left">
        {description}
      </p>
      <UnderlineButton
        className="text-xxs MenuMobileAnimation__Step FooterAnimation__Left"
        linkText={label}
        linkUrl={url}
      />
    </div>
  );
};
