import React from 'react';

const IconWealthsimple = ({ className = '' }) => {
  return (
    <svg className={className} viewBox="0 0 105 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.48597 8.68349L5.11237 3.00033C5.00586 2.76221 4.96021 2.57171 4.96021 2.39709C4.96021 2.01609 5.1428 1.77797 5.58404 1.60335V1.4446H0V1.60335C0.928138 1.8256 1.3846 2.33359 2.23666 4.14331L5.85792 12.5569L9.11401 5.69904L11.9593 12.5569L15.6871 4.17506C16.4478 2.53996 17.026 1.8256 17.7563 1.60335V1.4446H13.9525V1.60335C14.8046 1.80972 15.1393 2.31771 15.1393 3.42895C15.1393 4.50843 14.7285 5.95303 13.9373 7.63576L13.5569 8.44537L11.655 3.73057C11.4724 3.2702 11.3355 2.76221 11.3355 2.52409C11.3355 2.00022 11.6093 1.6986 12.1571 1.60335V1.4446H6.64912V1.60335C7.3186 1.68272 7.59247 1.96847 8.12501 3.22258L9.02272 5.42917L7.48597 8.68349Z"
        fill="#2A2A2A"
      />
      <path
        d="M24.4046 7.30239C24.2981 6.44515 24.1155 5.95303 23.7047 5.38154C23.02 4.42906 21.9093 3.88931 20.6769 3.88931C18.3641 3.88931 16.5383 5.77841 16.5383 8.1755C16.5383 10.4773 18.2728 12.2394 20.5551 12.2394C21.7267 12.2394 22.7614 11.7473 23.4613 10.8583C23.8873 10.3186 24.1308 9.8741 24.4655 8.93749L24.3286 8.87399C23.7047 9.84235 22.9896 10.2551 21.9093 10.2551C20.2204 10.2551 19.3836 9.28673 19.3683 7.30239H24.4046ZM19.3531 7.11189C19.3531 5.17517 19.8248 4.09569 20.6464 4.09569C21.1638 4.09569 21.4528 4.42906 21.5898 5.19105C21.6659 5.60379 21.6963 6.09591 21.6963 7.11189H19.3531Z"
        fill="#2A2A2A"
      />
      <path
        d="M34.0135 10.8583C33.7244 11.0171 33.5875 11.0647 33.4049 11.0647C33.1462 11.0647 32.918 10.9377 32.8419 10.7313C32.7506 10.5408 32.7354 10.3345 32.7354 9.73123V6.08003C32.7354 5.17517 32.5832 4.69893 32.142 4.33381C31.792 4.01631 31.366 3.88931 30.7574 3.88931C29.4489 3.88931 27.623 4.41318 26.4667 5.0958C25.569 5.63554 25.1581 6.19115 25.1581 6.84202C25.1581 7.49288 25.5994 7.95325 26.2232 7.95325C27.0905 7.95325 27.7143 7.31826 27.8361 6.31815C27.9578 5.31804 27.973 5.22279 28.1099 4.93705C28.2925 4.54018 28.7033 4.30206 29.1446 4.30206C29.4489 4.30206 29.6771 4.42906 29.8445 4.69893C29.9814 4.95292 30.0423 5.44504 30.0423 6.49277V7.31826C28.9468 7.65163 28.0034 8.00088 27.2427 8.33425C25.6755 9.04861 25.1429 9.58835 25.1429 10.4932C25.1429 11.5251 26.0254 12.2394 27.3035 12.2394C28.5055 12.2394 29.3119 11.7949 30.0423 10.7313C30.2401 11.7632 30.727 12.2394 31.579 12.2394C32.3398 12.2394 33.0549 11.8743 34.1048 10.9695L34.0135 10.8583ZM30.0423 9.96935C30.0423 10.6996 29.6923 11.16 29.1294 11.16C28.5512 11.16 28.2469 10.6361 28.2469 9.6836C28.2469 8.47712 28.6577 7.96913 30.0423 7.50876V9.96935Z"
        fill="#2A2A2A"
      />
      <path
        d="M33.9598 1.36523C34.4771 1.3811 34.751 1.5081 34.964 1.8256C35.2074 2.15897 35.2683 2.52409 35.2683 3.47657V10.0011C35.2683 11.6203 35.1466 11.8267 34.1423 11.8743V12.0331H39.0721V11.8743C38.0831 11.8267 37.9614 11.6044 37.9614 10.0011V0.0793737C37.0941 0.396869 35.3444 0.888986 33.9446 1.19061L33.9598 1.36523Z"
        fill="#2A2A2A"
      />
      <path
        d="M43.0703 4.09569V0.873112C42.0357 2.49234 40.2859 4.03219 38.5057 4.8418H40.3772V9.58835C40.3772 10.7313 40.5598 11.287 41.0619 11.7314C41.4423 12.0489 41.9748 12.2394 42.5682 12.2394C43.4051 12.2394 44.1658 11.9061 45.2918 11.0488L45.2005 10.9218C44.8353 11.0488 44.6223 11.0965 44.3332 11.0965C43.4355 11.0965 43.0703 10.6202 43.0703 9.41373V4.8418H44.6071L44.9875 4.09569H43.0703Z"
        fill="#2A2A2A"
      />
      <path
        d="M48.8677 0.0793737C47.8027 0.460368 46.2355 0.904861 44.8509 1.19061L44.8813 1.36523C45.3834 1.3811 45.6573 1.5081 45.8703 1.8256C46.1138 2.15897 46.1746 2.52409 46.1746 3.47657V10.0011C46.1746 11.6203 46.0377 11.8267 44.9574 11.8743V12.0331H49.5677V11.8743C49.2177 11.8426 49.0503 11.7314 48.959 11.4933C48.883 11.287 48.8677 11.1282 48.8677 10.3186V5.73079C49.3242 5.33392 49.6894 5.14342 50.0241 5.14342C50.2828 5.14342 50.5566 5.28629 50.6936 5.49266C50.8457 5.69904 50.8914 5.96891 50.8914 6.52452V10.3186C50.9066 11.6044 50.8153 11.7949 50.1763 11.8743V12.0331H54.4974V11.8743C53.6758 11.7949 53.5693 11.6044 53.5845 10.3186V6.11178C53.5845 5.25454 53.478 4.81005 53.1889 4.42906C52.8998 4.07981 52.4738 3.88931 51.9108 3.88931C50.9827 3.88931 50.2523 4.27031 48.8677 5.47679V0.0793737Z"
        fill="#2A2A2A"
      />
      <path
        d="M61.4249 4.34968C60.3142 4.01631 59.5078 3.88931 58.7318 3.88931C56.8603 3.88931 55.4605 5.00055 55.4605 6.49277C55.4605 7.65163 56.2365 8.41362 58.1689 9.14386L58.9144 9.4296C59.9795 9.82647 60.3447 10.2075 60.3447 10.8901C60.3447 11.5727 59.9034 12.0331 59.2187 12.0331C57.9406 12.0331 56.5256 10.5567 55.567 8.239H55.4149L55.567 11.3981C56.8908 11.9219 58.1993 12.2394 58.9448 12.2394C60.9076 12.2394 62.3227 11.033 62.3227 9.36611C62.3227 8.239 61.6227 7.50876 60.0099 6.95314L58.9753 6.6039C58.0167 6.27053 57.545 5.79429 57.545 5.1593C57.545 4.57193 58.0167 4.09569 58.6101 4.09569C59.5687 4.09569 60.7707 5.31804 61.4249 6.95314H61.5771L61.4249 4.34968Z"
        fill="#2A2A2A"
      />
      <path
        d="M62.5777 5.27042C63.0951 5.30217 63.3689 5.42917 63.582 5.73079C63.8254 6.08003 63.8863 6.44515 63.8863 7.39764V10.0011C63.8863 11.6203 63.7645 11.8267 62.7603 11.8743V12.0331H67.6901V11.8743C66.7011 11.8267 66.5794 11.6044 66.5794 10.0011V3.88931C65.7121 4.23856 63.9623 4.7783 62.5625 5.11167L62.5777 5.27042ZM65.0426 0C64.1601 0 63.4298 0.761988 63.4298 1.68272C63.4298 2.61933 64.1601 3.38132 65.0579 3.38132C65.9403 3.38132 66.6707 2.61933 66.6707 1.71447C66.6707 0.746113 65.9556 0 65.0426 0Z"
        fill="#2A2A2A"
      />
      <path
        d="M71.7948 3.88931C69.9081 4.55605 69.2386 4.76243 67.778 5.11167L67.8084 5.27042C68.3105 5.30217 68.5844 5.42917 68.7974 5.73079C69.0408 6.06416 69.1017 6.44515 69.1017 7.39764V10.0011C69.1017 11.6203 68.9648 11.8267 67.8845 11.8743V12.0331H72.4947V11.8743C71.8709 11.7949 71.7796 11.6044 71.7948 10.3186V5.63554C72.2208 5.20692 72.5099 5.06405 72.8751 5.06405C73.1642 5.06405 73.4381 5.20692 73.5598 5.44504C73.712 5.68316 73.7576 6.00066 73.7576 6.69915V10.3186C73.7576 11.1282 73.7424 11.287 73.6663 11.4933C73.575 11.7314 73.4077 11.8426 73.0577 11.8743V12.0331H77.1658V11.8743C76.5268 11.7949 76.4355 11.6044 76.4507 10.3186V5.63554C76.892 5.22279 77.2115 5.04817 77.5614 5.04817C77.8353 5.04817 78.094 5.19104 78.2309 5.39742C78.3679 5.61966 78.4135 5.96891 78.4135 6.69915V10.3186C78.4287 11.6044 78.3374 11.7949 77.6984 11.8743V12.0331H82.0195V11.8743C81.1979 11.7949 81.0914 11.6203 81.1066 10.3186V6.14353C81.1066 5.28629 80.9545 4.76243 80.6197 4.39731C80.3002 4.07981 79.8438 3.88931 79.3416 3.88931C78.3831 3.88931 77.531 4.33381 76.4507 5.39742C76.3594 4.88942 76.2681 4.66718 76.0247 4.41318C75.7052 4.07981 75.2183 3.88931 74.6401 3.88931C73.712 3.88931 72.723 4.41318 71.7948 5.39742V3.88931Z"
        fill="#2A2A2A"
      />
      <path
        d="M86.0567 3.88931C85.2655 4.22268 83.3788 4.79418 82.0551 5.11167L82.0703 5.27042C82.5876 5.30217 82.8463 5.42917 83.0593 5.73079C83.3027 6.06416 83.3636 6.44515 83.3636 7.39764V12.4458C83.3636 13.938 83.3332 14.2238 83.0897 14.5572C82.8919 14.8429 82.6333 14.954 81.9486 15.0016V15.1604H87.3805V15.0016C86.2089 14.954 86.0567 14.7318 86.0567 13.1284V11.6679C86.5741 12.0966 86.9697 12.2394 87.5478 12.2394C89.5715 12.2394 91.1539 10.2233 91.1539 7.66751C91.1539 5.42917 89.9975 3.88931 88.339 3.88931C87.5631 3.88931 86.8936 4.19094 86.0567 4.92117V3.88931ZM86.0567 5.1593C86.4828 4.87355 86.6958 4.7783 86.9544 4.7783C87.7913 4.7783 88.3086 6.08003 88.3086 8.20725C88.3086 10.4297 87.7456 11.7473 86.8023 11.7473C86.5132 11.7473 86.285 11.6521 86.0567 11.4139V5.1593Z"
        fill="#2A2A2A"
      />
      <path
        d="M91.5226 1.36523C92.0399 1.3811 92.3138 1.5081 92.5268 1.8256C92.7703 2.15897 92.8311 2.52409 92.8311 3.47657V10.0011C92.8311 11.6203 92.7094 11.8267 91.7052 11.8743V12.0331H96.635V11.8743C95.646 11.8267 95.5243 11.6044 95.5243 10.0011V0.0793737C94.657 0.396869 92.9072 0.888986 91.5074 1.19061L91.5226 1.36523Z"
        fill="#2A2A2A"
      />
      <path
        d="M104.939 7.30239C104.833 6.44515 104.65 5.95303 104.239 5.38154C103.555 4.42906 102.444 3.88931 101.211 3.88931C98.8986 3.88931 97.0728 5.77841 97.0728 8.1755C97.0728 10.4773 98.8073 12.2394 101.09 12.2394C102.261 12.2394 103.296 11.7473 103.996 10.8583C104.422 10.3186 104.665 9.8741 105 8.93749L104.863 8.87399C104.239 9.84235 103.524 10.2551 102.444 10.2551C100.755 10.2551 99.9181 9.28673 99.9028 7.30239H104.939ZM99.8876 7.11189C99.8876 5.17517 100.359 4.09569 101.181 4.09569C101.698 4.09569 101.987 4.42906 102.124 5.19105C102.2 5.60379 102.231 6.09591 102.231 7.11189H99.8876Z"
        fill="#2A2A2A"
      />
    </svg>
  );
};

export default IconWealthsimple;
