export const translations = {
  'en-ca': {
    'magazine_footer_tldr::subtitle':
      'Sign up for our weekly non-boring newsletter about money, markets, and more. Sorry, TLDR is currently available in English only.',
    'magazine_footer_tldr::button': 'Subscribe',
  },
  'fr-ca': {
    'magazine_footer_tldr::subtitle':
      'Abonnez-vous à notre amusante infolettre hebdomadaire sur l’argent, les marchés et plus encore. Nos excuses : TLDR n’est offerte qu’en anglais pour le moment.',
    'magazine_footer_tldr::button': 'Abonnez-vous',
  },
};
